import { createRouter, createWebHistory } from 'vue-router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

import { Session } from '@/utils/storage.js';

import menuRouter from './menuRouter.js';
const routes = [{
        path: '/',
        name: 'layout',
        component: () =>
            import ('@/components/layout/index.vue'),
        redirect: '/systemConfig',
        children: menuRouter
    },
    {
        path: '/login',
        name: 'Login',
        component: () =>
            import ('@/views/Login.vue')
    },
    {
        path: '/:pathMatch(.*)',
        component: () =>
            import ('@/views/notFound.vue'),

    }
]

NProgress.configure({ showSpinner: false })
console.log(process.env.BASE_URL)
const router = createRouter({
    // history: createWebHistory(process.env.BASE_URL),
    history: createWebHistory('/'),
    routes
})

router.beforeEach((to, from, next) => {
    NProgress.start();
    if (to.path !== '/login') {
        if (Session.get('token') === null) {
            router.push('/login')
        }
    }
    next();
})


router.afterEach(() => {
    NProgress.done();
})
export default router