export default [{
        path: '/home',
        name: 'layout',
        component: () =>
            import ('@/views/Home.vue'),
        hidden: true,
        meta: {
            title: '首页',
            icon: 'setting'
        }

    },
    {
        path: '/systemConfig',
        name: 'systemConfig',
        component: () =>
            import ('@/views/SystemConfig.vue'),
        meta: {
            title: '系统参数配置',
            icon: 'document'
        },
    },

    {
        path: '/dataDictionary',
        name: 'dataDictionary',
        component: () =>
            import ('@/views/DataDictionary.vue'),
        meta: {
            title: '数据字典管理',
            icon: 'tickets'
        }
    },
    {
        path: '/dataValue',
        name: 'dataValue',
        component: () =>
            import ('@/views/DataValue.vue'),
        hidden: true,
        meta: {
            title: '数据字典管理',
            icon: 'tickets',
            activePath: '/dataDictionary'
        }
    },
    {

        path: '/orgManagement',
        name: 'orgManagement',
        component: () =>
            import ('@/views/OrgManagement.vue'),
        meta: {
            title: '组织结构管理',
            icon: 'OfficeBuilding'
        }
    },
    {

        path: '/userManagement',
        name: 'userManagement',
        component: () =>
            import ('@/views/UserManagement.vue'),
        meta: {
            title: '用户管理',
            icon: 'user'
        }
    },
    {

        path: '/customerManagement',
        name: 'customerManagement',
        component: () =>
            import ('@/views/CustomerManagement.vue'),
        meta: {
            title: '客户管理',
            icon: 'user'
        }
    },
    // {
    //     path: '/districts',
    //     name: 'districts',
    //     component: import ('@/views/districts.vue'),
    //     meta: {
    //         title: '地区管理',
    //         icon: 'setting'
    //     }
    // },
    {
        path: '/equipment',
        name: 'equipment',
        component: () =>
            import ('@/views/Equipment.vue'),
        meta: {
            title: '设备管理',
            icon: 'setting'
        }
    },
    {
        path: '/question',
        name: 'question',
        component: () =>
            import ('@/views/Question.vue'),
        meta: {
            title: '常用问题管理',
            icon: 'setting'
        }
    }
]